import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

//Pages
import Tabs from "../Pages/Tabs";
import Roles from "../Pages/Roles";
import EventTypes from "../Pages/EventTypes";
import Players from "../Pages/Players";
import Teams from "../Pages/Teams";
import MatchType from "../Pages/MatchType";
import PenaltyRuns from "../Pages/PenaltyRuns";
import Competition from "../Pages/Competition";
import Events from "../Pages/Events";
import Commentary from "../Pages/Commentary";
import Users from "../Pages/Users";
import ImportMarket from "../Pages/ImportMarket";
import ManualEvent from "../Pages/ManualEvent";
import AddTabs from "../Pages/Tabs/AddTabs.jsx";
import AddRoles from "../Pages/Roles/AddRoles.jsx";
import AddEventTypes from "../Pages/EventTypes/AddEventType.jsx";
import AddPlayers from "../Pages/Players/AddPlayers.jsx";
import AddTeams from "../Pages/Teams/AddTeams.jsx";
import AddMatchType from "../Pages/MatchType/AddMatchTypes.jsx";
import AddPenaltyRuns from "../Pages/PenaltyRuns/AddPaneltyRuns.jsx";
import AddCompetition from "../Pages/Competition/AddCompetition.jsx";
import AddEvents from "../Pages/Events/AddEvents.jsx";
import AddCommentary from "../Pages/Commentary/AddCommentary.jsx";
import AddUsers from "../Pages/Users/AddUsers.jsx";
import AddMarketTemplate from "../Pages/MarketTemplate/AddMarketTamplate.js";
import ChangePassword from "../Pages/ChangePassword";
import Toss from "../Pages/Commentary/Toss.jsx";
import CommentaryMaster from "../Pages/Commentary/CommentaryMaster.js";
import AddBlock from "../Pages/Blocks/AddBlock.jsx";
import Blocks from "../Pages/Blocks";
import AddConfig from "../Pages/Config/AddConfig.jsx";
import Config from "../Pages/Config";
import PageFormat from "../Pages/PageFormat";
import AddPageFormat from "../Pages/PageFormat/AddPageFormat.jsx";
import AddPage from "../Pages/Page/AddPage.jsx";
import Page from "../Pages/Page";
import MenuList from "../Pages/menuList";
import AddMenuType from "../Pages/menuList/AddMenuType.js";
import AddMenuItem from "../Pages/menuList/AddMenuItem.js";
import News from "../Pages/News";
import Banner from "../Pages/Banner";
import API from "../Pages/API";
import APIEndpoints from "../Pages/APIEndpoints";
import Notification from "../Pages/Notification";
import Template from "../Pages/Template";
import RegisteredUsers from "../Pages/RegisteredUsers";
import RegistrationPending from "../Pages/RegistrationPending";
import MailSettings from "../Pages/MailSettings";
import Subscribers from "../Pages/Subscribers";
import MarketTemplate from "../Pages/MarketTemplate";
import EventMarkets from "../Pages/EventMarkets";
import AddEventMarket from "../Pages/EventMarkets/AddEventMarket.jsx";
import UnsettledMarket from "../Pages/UnsettledMarket";
import SetMarketResult from "../Pages/SetMarketResult";
import Vendor from "../Pages/Vendor";
import DisplayStatus from "../Pages/DisplayStatus";
import AddDisplayStatus from "../Pages/DisplayStatus/AddDisplayStatus.jsx";
import ClientSocket from "../Pages/ClientSocket"
// Import Authentication pages
import Login from "../Pages/Authentication/Login";
// import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
// import Register from "../Pages/Authentication/Register";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
// import Register1 from "../Pages/AuthenticationPages/Register";
// import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
// import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// Import Utility Pages
import Maintenance from "../Pages/Utility/Maintenance-Page.js";
import ComingSoon from "../Pages/Utility/ComingSoon-Page.js";
import Error404 from "../Pages/Utility/Error404-Page.js";
import Error500 from "../Pages/Utility/Error500-Page.js";
import AddNews from "../Pages/News/AddNews.jsx";
import { ShortCommentary } from "../Pages/Commentary/ShortCommentary.js";
import MatchTypePredictor from "../Pages/MatchType/MatchTypePredictor.jsx";
import UpdateCommentaryPlayer from "../Pages/Commentary/UpdateCommentaryPlayer.jsx";
import CommentaryMarketTemplate from "../Pages/Commentary/CommentaryMarketTemplate.jsx";
import { OpenMarket } from "../Pages/Commentary/OpenMarket.jsx";
import { CommentaryFeatures } from "../Pages/Commentary/CommentaryFeatures.jsx";
import MarketTemplateRunner from "../Pages/MarketTemplate/MarketTemplateRunner.js";
import { OddsView } from "../Pages/Commentary/OddsView.jsx";
import MarketLogs from "../Pages/EventMarkets/MarketLogs.jsx";
import MarketDataLogs from "../Pages/EventMarkets/MarketDataLogs.jsx";
import AddVendor from "../Pages/Vendor/AddVendor.js";
import VendorIpList from "../Pages/Vendor/VendorIpList.js";
import AddClientSocket from "../Pages/ClientSocket/AddClientSocket.jsx";
import AddBanner from "../Pages/Banner/AddBanner.jsx";
import AddAPI from "../Pages/API/AddAPI.jsx";
import AddAPIEndpoint from "../Pages/APIEndpoints/AddAPIEndpoint.jsx";
import PredictorApiLogs from "../Pages/Commentary/PredictorApiLogs.jsx";
import AddNotification from "../Pages/Notification/AddNotification.jsx";
import AddTemplate from "../Pages/Template/AddTemplate.jsx";
import AddRegisteredUsers from "../Pages/RegisteredUsers/AddRegisteredUsers.jsx";
import AddRegistrationPending from "../Pages/RegistrationPending/AddRegistrationPending.jsx";
import AddMailSettings from "../Pages/MailSettings/AddMailSettings.jsx";
import CommentaryLogs from "../Pages/CommentaryLogs";
import ThirdpartyLogs from "../Pages/ThirdpartyLogs";
import ErrorLogs from "../Pages/ErrorLogs";
import PredictorLogs from "../Pages/PredictorLogs";
import UndoLogs from "../Pages/UndoLogs";
import ScoringLogs from "../Pages/ScoringLogs";
import AddSocialMedia from "../Pages/SocialMedia/AddSocialMedia.jsx";
import SocialMedia from "../Pages/SocialMedia";
import AddAward from "../Pages/Awards/AddAwards.jsx";
import Awards from "../Pages/Awards";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/tabs", component: <Tabs /> },
  { path: "/addTabs", component: <AddTabs /> },
  { path: "/roles", component: <Roles /> },
  { path: "/addRoles", component: <AddRoles /> },
  { path: "/eventType", component: <EventTypes /> },
  { path: "/addEventType", component: <AddEventTypes /> },
  { path: "/events", component: <Events /> },
  { path: "/addEvents", component: <AddEvents /> },
  { path: "/Players", component: <Players /> },
  { path: "/addPlayer", component: <AddPlayers /> },
  { path: "/Teams", component: <Teams /> },
  { path: "/addTeams", component: <AddTeams /> },
  { path: "/matchType", component: <MatchType /> },
  { path: "/addMatchType", component: <AddMatchType /> },
  { path: "/matchTypePredictor", component: <MatchTypePredictor /> },
  { path: "/penalty", component: <PenaltyRuns /> },
  { path: "/addPenalty", component: <AddPenaltyRuns /> },
  { path: "/competition", component: <Competition /> },
  { path: "/socialMedia", component: <SocialMedia /> },
  { path: "/addSocialMedia", component: <AddSocialMedia /> },
  { path: "/awards", component: <Awards /> },
  { path: "/addAward", component: <AddAward /> },
  { path: "/addCompetition", component: <AddCompetition /> },
  { path: "/commentary", component: <Commentary /> },
  { path: "/addCommentary", component: <AddCommentary /> },
  { path: "/commentaryMaster", component: <CommentaryMaster /> },
  { path: "/shortCommentary", component: <ShortCommentary /> },
  { path: "/updateCommentaryFeature", component: <CommentaryFeatures /> },
  { path: "/updateCommentaryPlayer", component: <UpdateCommentaryPlayer /> },
  { path: "/predictorApiLogs", component: <PredictorApiLogs /> },
  {
    path: "/commentaryMarketTemplate",
    component: <CommentaryMarketTemplate />,
  },
  { path: "/openMarket", component: <OpenMarket /> },
  { path: "/oddsView", component: <OddsView /> },
  { path: "/autoEvent", component: <ImportMarket /> },
  { path: "/manualEvent", component: <ManualEvent /> },
  { path: "/Toss", component: <Toss /> },
  { path: "/users", component: <Users /> },
  { path: "/addUsers", component: <AddUsers /> },
  { path: "/blocks", component: <Blocks /> },
  { path: "/addblocks", component: <AddBlock /> },
  { path: "/config", component: <Config /> },
  { path: "/addConfig", component: <AddConfig /> },
  { path: "/addPageFormat", component: <AddPageFormat /> },
  { path: "/PageFormat", component: <PageFormat /> },
  { path: "/addPage", component: <AddPage /> },
  { path: "/Page", component: <Page /> },
  { path: "/menuList", component: <MenuList /> },
  { path: "/addMenuType", component: <AddMenuType /> },
  { path: "/addMenuItem", component: <AddMenuItem /> },
  { path: "/news", component: <News /> },
  { path: "/addNews", component: <AddNews /> },
  { path: "/subscribers", component: <Subscribers /> },
  { path: "/marketTemplate", component: <MarketTemplate /> },
  { path: "/addMarketTemplate", component: <AddMarketTemplate /> },
  { path: "/marketTemplateRunner", component: <MarketTemplateRunner /> },
  { path: "/eventMarkets", component: <EventMarkets /> },
  { path: "/addEventMarket", component: <AddEventMarket /> },
  { path: "/marketLogs", component: <MarketLogs /> },
  { path: "/marketDataLogs", component: <MarketDataLogs /> },
  { path: "/unsettledMarket", component: <UnsettledMarket /> },
  { path: "/setMarketResult", component: <SetMarketResult /> },
  { path: "/vendors", component: <Vendor /> },
  { path: "/addVendor", component: <AddVendor /> },
  { path: "/vendorIpList", component: <VendorIpList /> },
  { path: "/displaystatus", component: <DisplayStatus /> },
  { path: "/addDisplayStatus", component: <AddDisplayStatus /> },
  { path: "/clientSocket", component: <ClientSocket /> },
  { path: "/addClientSocket", component: <AddClientSocket /> },
  { path: "/banner", component: <Banner /> },
  { path: "/addBanner", component: <AddBanner /> },
  { path: "/apis", component: <API /> },
  { path: "/addApi", component: <AddAPI /> },
  { path: "/apiEndpoints", component: <APIEndpoints /> },
  { path: "/addApiEndpoint", component: <AddAPIEndpoint /> },
  { path: "/notification", component: <Notification /> },
  { path: "/addNotification", component: <AddNotification /> },
  { path: "/template", component: <Template /> },
  { path: "/addTemplate", component: <AddTemplate /> },
  { path: "/registeredUsers", component: <RegisteredUsers /> },
  { path: "/addRegisteredUsers", component: <AddRegisteredUsers /> },
  { path: "/registrationPending", component: <RegistrationPending /> },
  { path: "/addRegistrationPending", component: <AddRegistrationPending /> },
  { path: "/mailSettings", component: <MailSettings /> },
  { path: "/addMailSetting", component: <AddMailSettings /> },
  { path: "/commentaryLogs", component: <CommentaryLogs /> },
  { path: "/thirdpartyLogs", component: <ThirdpartyLogs /> },
  { path: "/errorLogs", component: <ErrorLogs /> },
  { path: "/predictorLogs", component: <PredictorLogs /> },
  { path: "/undoLogs", component: <UndoLogs /> },
  { path: "/scoringLogs", component: <ScoringLogs /> },

  {
    path: "/changePassword",
    component: <ChangePassword />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];
const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  // { path: "/auth-register", component: <Register1 /> },
  // { path: "/auth-recoverpw", component: <RecoverPassword /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
